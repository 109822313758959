<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template lang="pug">

    vs-sidebar.add-new-data-sidebar.items-no-padding(click-not-close="" position-right="" parent="body" default-index="1" color="primary" spacer="" v-model="isSidebarActiveLocal")
      // หัวเรื่อง
      .mt-6.flex.items-center.justify-between.px-6
        h4
          | ข้อมูลปรากฎหน้าเว็บไซต์
          //- | {{ Object.entries(this.data).length === 0 ? "ADD NEW" : "UPDATE" }} ITEM
        feather-icon.cursor-pointer(icon="XIcon" @click.stop="isSidebarActiveLocal = false")
      // เส้นแบ่ง
      vs-divider.mb-0

      // VuePerfectScroll
      vue-perfect-scrollbar.scroll-area--data-list-add-new(:settings="settings" :key="$vs.rtl")

        // FORM ใส่ข้อมูล
        .p-6
          .mb-base
            //- h6.mb-4 ข้อมูลแสดงสินค้าบนหน้าเว็บไซต์
          .flex.items-center.mb-8
            vs-switch(v-model="webItem")
            span.ml-4 แสดงบนหน้าเว็บไซต์
          //-  div.mt-12
          //- h6.mb-8 แสดงในหมวด
          .flex.items-center.mb-8
            vs-switch(v-model="hotItem")
            span.ml-4 สินค้ากำลังเป็นที่นิยม (Hot Item)
          .flex.items-center.mb-8
            vs-switch(v-model="newArrival")
            span.ml-4 สินค้าเข้าใหม่ (New Arrival)
          //- .flex.items-center.mb-8
          //-   vs-switch(v-model="recommended")
          //-   span.ml-4 สินค้าแนะนำ (Recommended)
          div.mt-12
            h6.mb-4 หมวดหมู่สินค้าหน้าเว็บไซต์
            // เลือก WEB CATEGORY
            .grid-layout-container.alignment-block(style="width:100%;")
              vs-row(vs-w='12')
                vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
              vs-row(vs-w='12')
                vs-col(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
                  multiselect(
                    v-model="webcat_value",
                    :options="allWebcat",
                    :multiple="true",
                    :close-on-select="false",
                    :group-select="true",

                    placeholder="พิมพ์เพื่อค้นหา",

                  )
                    span(slot="noResult").
                      ไม่พบข้อมูล


      // **************** SUBMIT ****************
      .flex.flex-wrap.items-center.p-6(slot="footer")
        //- vs-button.mr-6(@click="submitData" :disabled="!isFormValid") Submit
        vs-button.mr-6(@click="submitData" ) บันทึก
        vs-button(type="border" color="danger" @click="isSidebarActiveLocal = false") ยกเลิก

</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import shapeFormat from "@/mixins/shapeFormat.js";
import Multiselect from "vue-multiselect";
import vSelect from "vue-select";
import {
  convertToArray
} from '@/utils'


export default {
  mixins: [shapeFormat],
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  watch: {

     webcat_value : {
        handler(val) {
          if(val === null || val === undefined ){
            this.data.webCategory = "";
          }else if (val.length <= 0){
            this.data.webCategory = "";
          }else {
             this.data.webCategory = val.join("|");
          }
        },
        deep: true

     },

    data: {
       handler(value) {

        // let selected = value.webCategory.split("|");
        if(value.webCategory === null || value.webCategory === undefined || value.webCategory === "" ){
          this.webcat_value = []
        }else{
          this.webcat_value = value.webCategory.split("|");
        }


        if(value.webItem === "y")  this.webItem = true
        else this.webItem = false

        if(value.webHotItem === 'ใช่')  this.hotItem = true
        else this.hotItem = false



       },
       deep: true

    },
    webItem: function(value){
      if(value === true) this.data.webItem = "y"
      else this.data.webItem = "n"

    },
    hotItem: function(value){

      if(value)  this.data.webHotItem = 'ใช่'
      else this.data.webHotItem = 'ไม่ใช่'
    },
    newArrival: function(value){

      if(value)  this.data.webNewArrival = 'y'
      else this.data.webNewArrival = 'n'
    },
    isSidebarActive(val) {
      if (!val) return;
      // if (Object.entries(this.data).length === 0) {

            if(this.data.webCategory === null || this.data.webCategory === undefined || this.data.webCategory === "" ){
              this.webcat_value = []
            }else{
              this.webcat_value = this.data.webCategory.split("|");

            }


            if(this.data.webItem === "y")  this.webItem = true
            else this.webItem = false

            if(this.data.webHotItem === 'ใช่')  this.hotItem = true
            else this.hotItem = false

            if(this.data.webNewArrival === 'y')  this.newArrival = true
            else this.newArrival = false

      // } else {
      //   let { category, id, img, name, order_status, price } = JSON.parse(
      //     JSON.stringify(this.data)
      //   );
      //   this.dataId = id;
      //   this.dataCategory = category;
      //   this.dataImg = img;
      //   this.dataName = name;
      //   this.dataOrder_status = order_status;
      //   this.dataPrice = price;
      //   this.initValues();
      // }
      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    }
  },
  data() {
    return {
      pg : {},
      webItem:false,
      hotItem: false,
      newArrival: false,
      recommended: false,
      webcat_value: [],
      allWebcat: [],
      category_choices: [
        { text: "Audio", value: "audio" },
        { text: "Computers", value: "computers" },
        { text: "Fitness", value: "fitness" },
        { text: "Appliance", value: "appliance" }
      ],

      order_status_choices: [
        { text: "Pending", value: "pending" },
        { text: "Canceled", value: "canceled" },
        { text: "Delivered", value: "delivered" },
        { text: "On Hold", value: "on_hold" }
      ],
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },
  async created(){
    await this.getAllWebcat();
  },

  async mounted() {


  },
  computed: {
    webcatObserver : {
      get(){

        return this.data.webCategory;
      },
      set(val){
        if(val === null || val === undefined || val === "" ){
          this.webcat_value = []
        }else{
          this.webcat_value = val.split("|");
        }
      }
    },
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {

        if (!val) {
          this.$emit("closeSidebar");
          // this.$validator.reset()
          // this.initValues()
        }
        else{
            if(this.data.webCategory === null || this.data.webCategory === undefined || this.data.webCategory === "" ){
              this.webcat_value = []
            }else{
              this.webcat_value = this.data.webCategory.split("|");
            }


            if(this.data.webItem === "y")  this.webItem = true
            else this.webItem = false

            if(this.data.webHotItem === 'ใช่')  this.hotItem = true
            else this.hotItem = false

             if(this.data.webNewArrival === 'y')  this.newArrival = true
            else this.newArrival = false
        }


      }
    },
    isFormValid() {
      return (
        !this.errors.any() &&
        this.dataName &&
        this.dataCategory &&
        this.dataPrice > 0
      );
    }
  },
  methods: {
    async getAllWebcat() {
      var allcate = await this.getOne("/api/webcategory");
      this.allWebcat =  convertToArray(allcate, "description");

    },
    initValues() {
      // if (this.data.id) return;
      // this.dataId = null;
      // this.dataName = "";
      // this.dataCategory = null;
      // this.dataOrder_status = "pending";
      // this.dataPrice = 0;
      // this.dataImg = null;

      // this.webItem = false;
      // this.hotItem = false;
      // this.recommended = false;
    },
    async submitData() {

      // if(this.webcat_value.length > 1)
      //   this.data.webCategory =  this.webcat_value.join("|");
      // else{
      //   this.data.webCategory =  this.webcat_value[0];
      // }



      await this.$store.dispatch("productGroup/updateOnlyProductGroup", this.data);
      this.$emit("closeSidebar");
      // this.initValues();

      // this.$validator.validateAll().then(result => {
      //   if (result) {
      //     const obj = {
      //       id: this.dataId,
      //       name: this.dataName,
      //       img: this.dataImg,
      //       category: this.dataCategory,
      //       order_status: this.dataOrder_status,
      //       price: this.dataPrice
      //     };

      //     if (this.dataId !== null && this.dataId >= 0) {
      //       this.$store.dispatch("dataList/updateItem", obj).catch(err => {
      //         console.error(err);
      //       });
      //     } else {
      //       delete obj.id;
      //       obj.popularity = 0;
      //       this.$store.dispatch("dataList/addItem", obj).catch(err => {
      //         console.error(err);
      //       });
      //     }

      //     this.$emit("closeSidebar");
      //     this.initValues();
      //   }
      // });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();
        reader.onload = e => {
          this.dataImg = e.target.result;
        };
        reader.readAsDataURL(input.target.files[0]);
      }
    }
  },
  components: {
    VuePerfectScrollbar,
    Multiselect,
    "v-select": vSelect

  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
