<template lang="pug">
div#main-background.px-24.px-24
  update-product-sidebar(:isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar" :data="sidebarData")
  vs-card#controller-panel( vs-type='flex' vs-justify='flex-end' style="position: fixed; display:block; z-index: 9999 !important; top : 85%;width:64%;")
    div#general.pt-6( vs-type='flex' vs-justify='flex-end' )
      // Rental Price
      vs-row(vs-w='12')
        vs-col.px-2.typo__label(vs-type='flex',  vs-justify='flex-end', vs-align='center', vs-lg='6', vs-sm='6', vs-xs='0')
        vs-col.px-2.typo__label(vs-type='flex',  vs-justify='flex-end', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='4')
          vs-button.w-full(color="success" type="filled" @click="saveChange()") บันทึก
        vs-col.px-2.typo__label(vs-type='flex',  vs-justify='flex-start', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='8')
         vs-button.w-full(color="dark" type="filled"  @click="$router.push('/backoffice/stock/')") กลับหน้าสต็อค

  vs-card.px-12.pt-6.pb-6( vs-justify='center' )
    div#general.px-16.pt-6
      vs-row(vs-w='12')
        vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='6', vs-sm='6', vs-xs='6')
          h2 ข้อมูลทั่วไป
        vs-col.px-1(vs-type='flex', vs-justify='flex-end', vs-align='center', vs-lg='6', vs-sm='6', vs-xs='6')
          //- feather-icon(icon='DeleteIcon' svgclasses='w-4 h-4' svgClasses="stroke-current text-danger")
          //-   span.ml-2(style="color:rgb(var(--vs-danger))") Delete
          vs-dropdown.cursor-pointer(vs-custom-content='' )
            .px-2.pt-1.aui-button(vs-type="flex", vs-align="center", style=" border-radius:5px;  ")
              i.material-icons  more_horiz
              vs-dropdown-menu
                ul
                  li.flex.py-4.px-4.cursor-pointer(class='hover:bg-primary hover:text-white' @click.stop="editData(productGroup)")
                    feather-icon(icon='EditIcon' svgclasses='w-4 h-4' )
                    span.ml-2 ข้อมูลหน้าเว็บ
                  li.flex.py-4.px-4.cursor-pointer(class='hover:bg-primary hover:text-white' @click="$router.push('/backoffice/product-review/' + productgroupid)" )
                    feather-icon(icon='MessageCircleIcon' svgclasses='w-4 h-4' )
                    span.ml-2 ภาพรีวิวสินค้า
                  li.flex.py-4.px-4.cursor-pointer(class='hover:bg-primary hover:text-white' @click="removeAll()")
                    feather-icon(icon='DeleteIcon' svgclasses='w-4 h-4' )
                    span.ml-2 ลบสินค้านี้ !

      // General product group
      vs-row.pb-6(vs-w='12')
      
        template(v-if="productGroup.mainImage")
          
          .img-container.w-64.mx-auto.flex.items-center.justify-center.pt-4(vs-type='flex', vs-justify='flex-start', vs-align="flex-start")
            .grid-layout-container.alignment-block
              vs-row.py-2
                vs-col( vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12',  style="font-size: 1rem; color: #0679ff; text-align:center;width:100%;") รูปภาพสำหรับเว็บไซต์
              vs-row
                vs-col( vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
                  img.pb-6.responsive(:src="getImagepath(productGroup.mainImage)" alt="img" style="width: 200px")
                  i.material-icons.px-1(style=" cursor:pointer; font-size:18px; margin-right:5px;vertical-align:middle;"  @click="removeItemImage()" ) cancel
        template(v-else)
          template(v-if="dataImg")
            // Image Container
            .img-container.w-64.mx-auto.flex.items-center.justify-center.pt-12(vs-type='flex', vs-justify='flex-start', vs-align="flex-start")
              .grid-layout-container.alignment-block
                vs-row.py-2
                  vs-col( vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12',  style="font-size: 1rem; color: #0679ff; text-align:center;width:100%;") รูปภาพสำหรับเว็บไซต์
                vs-row
                  vs-col( vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
                    img.responsive(:src="dataImg" alt="img" style="width: 200px")
                    i.material-icons.px-1(style=" cursor:pointer; font-size:18px; margin-right:5px;vertical-align:middle;"  @click="removeItemImage()" ) cancel

          // Image upload Buttons
          vs-col.px-6.pb-6.pt-6( vs-type='flex', vs-justify='center', vs-align='center', vs-lg='12', vs-sm='12', vs-xs='12')
            .upload-img.mt-5(v-if="!dataImg")
              div.py-4(vs-type='flex', vs-justify='center', style="font-size: 1rem; color: #0679ff; text-align:center;") รูปภาพสำหรับเว็บไซต์
              input.hidden(type="file" ref="mainUploadImgInput" @change="updateCurrImg" accept="image/*")
              .upload_image(@click="$refs.mainUploadImgInput.click()" vs-type='flex', vs-justify='flex-start', vs-align='flex-start' )
              //- div.py-4(vs-type='flex', vs-justify='center', style="font-size: 1rem;") รูปภาพขนาดไม่เกิน 1 MB
              //- div สกุลภาพ .png หรือ .jpg

            // Image upload Buttons เส้นประ
            .modify-img.flex.justify-between.mt-3
              input.hidden(type="file" ref="updateImgInput" @change="updateCurrImg" accept="image/*")



        // Product Name
        vs-row.pb-6(vs-w='12')
          vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-end', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='3') ชื่อสินค้า :

          vs-col.px-3(vs-type='flex',  vs-justify='flex-start', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
            vs-input.w-full( v-model="productGroup.name" )

        // Code
        vs-row.pb-6(vs-w='12')
          vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-end', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='3') รหัสสินค้า :
          vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-start', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7') {{productGroup.code}}

        // Category
        vs-row.pb-6(vs-w='12')
          vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-end', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='3') ประเภท :
          vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-start', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
            multiselect(

                v-model="categorySelected",
                :options="categories",
                label="description"
                track-by="code",
              )
              span(slot="noResult").
                ไม่พบข้อมูล

        // Texture
        vs-row.pb-6(vs-w='12')
          vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-end', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='3') ชนิด :
          vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-start', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
            multiselect(
              v-model="textureSelected",
              :options="textures",
              label="description"
              track-by="code",

            )
            span(slot="noResult").
              ไม่พบข้อมูล

        // Rental Price
        vs-row.pb-6(vs-w='12')
          vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-end', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='3') ราคาเช่า :
          vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-start', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
            CurrencyValue.w-full( v-model="productGroup.rentalPrice")
          vs-col(vs-type='flex',  vs-justify='flex-start', vs-align='center', vs-lg='2', vs-sm='2', vs-xs='2')
            .w-full.sm-btn-green( type="filled" @click="applyAllRentalPrice()") ใช้ทั้งหมด

        // Bail
        vs-row.pb-6(vs-w='12')
          vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-end', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='3') ค่าประกัน :
          vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-start', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
            CurrencyValue.w-full( v-model="productGroup.bail")
          vs-col(vs-type='flex',  vs-justify='flex-start', vs-align='center', vs-lg='2', vs-sm='2', vs-xs='2')
            .w-full.sm-btn-green( type="filled" @click="applyAllBail()") ใช้ทั้งหมด




        // Sale Price
        //- vs-row.pb-6(vs-w='12')
        //-   vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-end', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='3') ราคาขาย :
        //-   vs-col.px-3.typo__label(vs-type='flex',  vs-justify='flex-start', vs-align='center', vs-lg='7', vs-sm='7', vs-xs='7')
        //-     CurrencyValue.w-full( v-model="salePrice")




        // เลือกสี และขนาด
        vs-row(vs-w='12')
          vs-col.px-6(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='6', vs-sm='6', vs-xs='12')
            label.typo__label(style="width:100%;") สี
              multiselect(
                v-model="color_value",
                :options="colors",
                :multiple="true",
                :group-select="true",
                label="description"
                track-by="code",
                placeholder="พิมพ์เพื่อค้นหา",

              )
                span(slot="noResult").
                  ไม่พบข้อมูล

          vs-col.px-6(vs-type='flex', vs-justify='center', vs-align='center', vs-lg='6', vs-sm='6', vs-xs='12')
            label.typo__label(style="width:100%;") ขนาด
              multiselect(
                v-model="size_value",
                :options="sizes",
                :multiple="true",
                :group-select="true",
                label="description"
                track-by="code",
                placeholder="พิมพ์เพื่อค้นหา",

              )
                span(slot="noResult").
                  ไม่พบข้อมูล



  //- สี PANEL
  vs-card.px-3.pt-12.pb-12( vs-justify='center' v-if="color_value.length > 0 && size_value.length > 0" v-for="(color_i,colorInd) in color_value" :key="colorInd")
    div
      vs-row(vs-w='12')
        vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='12')
          .grid-layout-container.alignment-block(style="width:100%;")
            vs-row(vs-w='12')
              vs-col.px-6.typo__label(vs-type='flex', vs-justify='center', vs-align='center',vs-w='12' ) {{color_i.description}}
            vs-row(vs-w='12')
              vs-col.px-6.typo__label(vs-type='flex', vs-justify='center', vs-align='center',vs-w='12' )
                AddImageComponent( :key="imageCodeKey(addImageCode,color_i.code)" :productGroupId="productgroupid" :color="color_i.code" :productList="productList")
                //- .upload-img(v-if="!prdImage[0]" )
                //-   input.hidden(type="file" ref="uploadImgInput" @change="updateCurrImg" accept="image/*")
                //- .upload_image(@click="$refs.uploadImgInput.click()" vs-type='flex', vs-justify='flex-start', vs-align='flex-start' style="margin: 0 auto;" )





        vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='1', vs-sm='1', vs-xs='0')
        vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='8', vs-sm='8', vs-xs='12')
          //- ขนาด SIZE
          .grid-layout-container.alignment-block(style="width:100%;")
              vs-row.pb-3(vs-w='12' v-for="(size_i,sizeInd) in size_value " :key="sizeInd")
                vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='2', vs-sm='2', vs-xs='2'  )
                  .grid-layout-container.alignment-block(style="width:100%;")
                    vs-row(vs-w='12')
                      vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-w='12')
                        p(style="font-size:1.3em; font-weight:bold !important;") ขนาด {{size_i.description}}

                vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='2', vs-sm='2', vs-xs='2'  )
                  .grid-layout-container.alignment-block(style="width:100%;")
                    vs-row(vs-w='12')
                      vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-w='12')
                        p(style="font-size:1.3em; ") จำนวน
                      vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-w='12')
                        CurrencyValue.w-full( v-model="productList[(colorInd * size_value.length)  + sizeInd]['inStock']")
                vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='3')
                  .grid-layout-container.alignment-block(style="width:100%;")
                    vs-row(vs-w='12')
                      vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-w='12')
                        p(style="font-size:1.3em;") ราคาเช่า
                      vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-w='12')
                        CurrencyValue.w-full( v-model="productList[(colorInd * size_value.length)  + sizeInd]['rentalPrice']") )
                vs-col.px-1(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-lg='3', vs-sm='3', vs-xs='3')
                  .grid-layout-container.alignment-block(style="width:100%;")
                    vs-row(vs-w='12')
                      vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-w='12')
                        p(style="font-size:1.3em;") ค่าประกัน
                      vs-col(vs-type='flex', vs-justify='flex-start', vs-align='center', vs-w='12')
                        CurrencyValue.w-full( v-model="productList[(colorInd * size_value.length)  + sizeInd]['bail']") )


</template>
<script>
import Multiselect from "vue-multiselect";
import vSelect from "vue-select";
import CurrencyValue from "./Components/CurrencyValue";
import UpdateProductSidebar from "./Components/UpdateProductSidebar.vue";
// import moduleProductGroup from "@/store/product-group/moduleProductGroup.js";
// import moduleProductList from "@/store/product-list/moduleProductList.js";
import shapeFormat from "@/mixins/shapeFormat.js";
import AddImageComponent from "./AddImageComponent.vue";

export default {
  mixins: [shapeFormat],
  props: ["msg"],
  data() {
    return {
      addNewDataSidebar: false,
      sidebarData: {},
      c10: ["px-10", "pb-10"],
      aa: ["ssdf", "2", "3", "4", "5"],
      rentalPrice: 0,
      bail: 0,
      salePrice: 0,
      amount: 0,
      categorySelected: {},
      categories: [],
      cates: [],
      textureSelected: {},
      textures: [],

      dataImg: "",
      productName: "",
      category: "",
      texture: "",
      code: "ไม่มี",
      colors: [],

      sizes: [],
      productgroupid: "",
      color_value: [],
      size_value: [],
      selected: ["foo", "bar"],
      options: ["foo", "bar", "baz"],
      productGroup: {},
      prdImage: [],
      productList: [],
      pdlist: [],
      addImageCode: 0,
      // สำหรับ  check สินค้าจำนวนรวมกับ stock
      stocks: []
    };
  },

  computed: {
    getImages() {
      return this.$store.getters["productList/images"];
    }
  },
  async created() {
    // INIT productGroup
    this.productgroupid = this.$route.params.productgroupid;
    await this.$store.dispatch("productGroup/set", this.productgroupid);

    this.productGroup = await this.$store.getters["productGroup/productGroup"];

    await this.$store.dispatch(
      "productList/fetchColorList",
      this.productgroupid
    );

    await this.$store.dispatch(
      "productGroup/getAllData",
      this.productGroup.shopInfoId
    );

    this.categories = this.$store.getters["productGroup/categories"];
    this.textures = this.$store.getters["productGroup/textures"];
    this.colors = this.$store.getters["productGroup/colors"];
    this.sizes = this.$store.getters["productGroup/sizes"];

    this.categorySelected = await this.$store.dispatch(
      "productGroup/getCategoryObject"
    );

    this.textureSelected = await this.$store.dispatch(
      "productGroup/getTextureObject"
    );

    // LOAD Stock
    var responseStock;
    try {
      responseStock = await this.$http.get(
        this.$store.state.apiURL + "/api/stock/" + this.$route.params.productid,
        this.$store.state.auth.apiHeader
      );
      this.stocks = responseStock.data;
      // console.log("STOCK RESPONSEStock >>> ", responseStock.data);
    } catch (error) {
      //  console.log("responseStock error >>> ", error);
      responseStock = null;
      // appropriately handle the error
    }

    this.color_value = await this.$store.dispatch("productGroup/getColors");

    this.size_value = await this.$store.dispatch("productGroup/getSizes");

    var resp = null;
    try {
      resp = await this.$http.post(
        this.$store.state.apiURL +
          "/api/productitem/group/" +
          this.productGroup.productGroupId,
        {},
        this.$store.state.auth.apiHeader
      );
    } catch (error) {
      resp = null;
      this.$swal({
        icon: "error",
        title: "ไม่สามารถรับข้อมูลได้"
      });
    }
    this.pdlist = resp.data;

    setTimeout(async () => {
      await this.$store.dispatch(
        "productList/listInGroup",
        this.productgroupid
      );

      this.productList = await this.$store.getters["productList/products"];
      // console.log('this.productList >>>> ',this.productList.map( x => x.code));

      this.changeProductList();
    }, 500);
  },

  watch: {
    productList: {
      handler(value, oldvalue) {
        // console.log('productList  CHANGE !!!>>> ',value[0].inStock);
        // console.log('productList  OLD !!!>>> ',oldvalue[0].inStock);

        // ดักว่า จำนวน
        value.forEach((ele, key) => {
          // หาว่า ITEM ID นี้ในสต็อคมีเท่าไร
          var thisItem = this.stocks.filter(
            x => x.productItemId === ele.productItemId
          );
          var inBranchStock = 0;

          // หาจำนวนรวมในแต่ละสาขา
          if (thisItem.length > 0) {
            inBranchStock = thisItem.sum(s => s.inStock);
          }

          // ************ เทียบจำนวนรวม กับจำนวนในแต่ละสาขา ************
          /* จำนวนรวม < ผลรวมจำนวนแยกสาขา */
          if (thisItem.inStock < inBranchStock) {
            // ต้องใช้จำนวนเก่า
            thisItem.inStock = oldvalue[key].inStock;
            // console.log('thisItem.inStock >>>> ',thisItem.inStock, 'ผลรวมตามสาขา >>>>> ',inBranchStock ,' instock จำนวนเดิม ' ,oldvalue[key].inStock );
          }
        });
        // this.productGroup.colors = value;
        // this.changeProductList();
      },
      deep: true
    },
    categorySelected: function(cate) {
      this.productGroup.category = cate.code;
    },
    textureSelected: function(value) {
      this.productGroup.texture = value.code;
    },
    color_value: {
      handler(value) {
        // // เพิ่มสี

        this.productGroup.colors = value;
        this.changeProductList();
      },
      deep: true
    },
    size_value: {
      handler(value) {
        // // เพิ่มสี

        this.productGroup.sizes = value;
        this.changeProductList();
      },
      deep: true
    },
    productGroup: {
      handler() {
        // console.log(' MMMMMAAAAAAAAAIIIIIINNNNNN productGroup> .>>>>>>>> ',this.productGroup);
        // this.$store.dispatch("productList/removeAll");
        // for (let i = 0; i < this.productList.length; i++) {
        //   var p = {};
        //   Object.assign(p, this.productList[i]);
        //   this.$store.dispatch("productList/add", p);
        // }
      },
      deep: true
    }
  },
  methods: {
    // ************** FOR SIDE BAR **************

    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
    editData() {
      this.sidebarData = {};
      let data = Object.assign({}, this.productGroup);
      this.sidebarData = data;

      this.toggleDataSidebar(true);
    },
    addNewData() {
      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },

    // ************** FOR SIDE BAR **************

    imageCodeKey(thekey, colorcode) {
      return thekey + colorcode;
    },

    applyAllRentalPrice() {
      for (let ind = 0; ind < this.productList.length; ind++) {
        this.productList[ind].rentalPrice = this.productGroup.rentalPrice;
        this.$set(this.productList, ind, this.productList[ind]);
      }
      this.$store.dispatch("productList/applyAll", {
        key: "rentalPrice",
        value: this.productGroup.rentalPrice
      });
    },
    applyAllBail() {
      for (let ind = 0; ind < this.productList.length; ind++) {
        this.productList[ind].bail = this.productGroup.bail;
        this.$set(this.productList, ind, this.productList[ind]);
      }
      this.$store.dispatch("productList/applyAll", {
        key: "bail",
        value: this.productGroup.bail
      });
    },
    getFullpath(mainImage) {
      if (
        mainImage === undefined ||
        mainImage === null ||
        mainImage === "" ||
        mainImage === this.$store.state.imageStore
      ) {
        return this.$store.state.defaultImage;
      } else {
        return this.$store.state.imageStore + mainImage;
      }
    },
    changeProductList() {
      // ลบที่เคยมีออกก่อน
      // this.$store.dispatch("productList/removeAll");
      // var prior = this.productList.length;
      // console.log(
      //   "REMOVE ALL :::: >>>> ",
      //   this.$store.state.productList.products
      // );

      // moduleProductList.actions.removeAll();
      // สร้าง Product Item ทีละตัว
      var list = [];
      Object.assign(list, this.productList);

      this.productList = [];

      // console.log('PRODUCT CHANGE >>> ',list);

      for (let i = 0; i < this.color_value.length; i++) {
        for (let j = 0; j < this.size_value.length; j++) {
          var res = list.filter(
            c =>
              this.color_value[i].code === c.color &&
              this.size_value[j].code === c.size
          );

          // console.log("(i,j)" ,i ,",",j, ' COLOR  >>> ', this.color_value[i].code ,' SIZE  >>> ',this.size_value[j].code);
          // console.log("res >>> ",res);
          // ถ้าเคยมีอยู่ใน list
          if (res.length >= 1) {
            this.$set(this.productList, i * this.size_value.length + j, res[0]); //(colorInd * size_value.length)  + sizeInd
          } else {
            var pl = {};
            Object.assign(pl, this.productGroup);
            delete pl.colors;
            delete pl.sizes;
            pl.color = this.color_value[i].code;
            pl.size = this.size_value[j].code;
            // ถ้าครั้งแรก ใส่เป็น standard price ไปก่อน
            pl.mainImage = null;
            pl["rentalPrice"] = 0;
            pl["bail"] = 0;
            // this.productList.unshift(pl);
            this.$set(this.productList, i * this.size_value.length + j, pl);
          }

          // var p = {};
          // Object.assign(p, this.productGroup);
          // delete p.colors;
          // delete p.sizes;
          // // ใส่ color size กับ color code
          // p.color = this.color_value[i].code;
          // p.size = this.size_value[j].code;
          // this.$store.dispatch("productList/add", p);
        }
      }
      this.addImageCode++;
      // this.$store.dispatch("productList/add", pl);
    },
    changeToName() {},

    async removeAll() {
      this.$swal({
        title: "<strong>ลบทั้งหมด </strong>",
        html: "ข้อมูลทั้งหมดที่ลงไว้จะถูก <b>ลบทั้งหมด</b> ",

        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ลบข้อมูลทั้งหมด"
      }).then(async result => {
        if (result.value) {
          this.$vs.loading();
          var res = null;
          try {
            res = await this.$store.dispatch(
              "productGroup/removeAll",
              this.productgroupid
            );
            if (res.status === 200) {
              setTimeout(() => {
                this.vs.loading.close();
              }, 300);
              this.$router.push("/backoffice/stock");
            }
          } catch (error) {
            res = error;
            this.$vs.loading.close();
          }
        }
      });
    },
    async saveChange() {
      // update ที่ store update productGroup
      // ค่อยๆ add product list ทีละตัว โดยต้องเอาข้อมูล บางอย่างของ product group update ใหม่
      // เช่น productGroup.name  / productGroup.code / productGroup.category /productGroup.texture
      // ต้องใส่ main image ของแต่ละสี

      if (
        this.numberFormat(this.size_value.length) *
          this.numberFormat(this.color_value.length) <=
        0
      ) {
        this.$swal({
          icon: "error",
          title: "โปรดเลือกอย่างน้อย 1 สีและ 1 ไซส์"
        });
        return;
      }

      this.$vs.loading({ type: "radius" });

      // 2020-10-03 เพิ่มต้องเลือกสีและ size ก่อน

      for (let k = 0; k < this.productList.length; k++) {
        if (
          this.productList[k].mainImage === "" ||
          this.productList[k].mainImage === null
        ) {
          var re = this.getImages.filter(
            s => s.color === this.productList[k].color
          );
          // console.log('this.getImages.filter >>> ',re);

          if (re.length > 0) {
            // console.log('เจอ >>> ',re[0].color);
            this.productList[k].mainImage = re[0].fileName;
          }
        }
      }

      var all = {};
      all.productGroup = this.productGroup;
      all.productList = this.productList;
      // all.image = this.dataImg;

      all.productGroup.base64 = this.dataImg;

      try {
        await this.$store.dispatch("productGroup/updateChange", all);
      } catch (err) {
        this.$swal({
          title: "<strong>มีบางรายการกำลังออกบิลอยู่</strong>",
          html: "ข้อมูลทั้งหมดที่ลงไว้จะถูก <b>ลบทั้งหมด</b> ",

          icon: "warning",
          // showCancelButton: true,
          confirmButtonColor: "#3085d6",
          // cancelButtonColor: "#d33",
          confirmButtonText: "ลบข้อมูลทั้งหมด"
        }).then(async result => {
          if (result.value) {
            location.reload();
          }
        });
      }

      setTimeout(() => {
        this.$vs.loading.close();
        // this.noticeSuccess("บันทึกสำเร็จ");
      }, 500);
      // moduleProductGroup.actions.addProductGroup();
    },
    removeItemImage() {
      this.$swal({
        title: "ต้องการลบรูปใช่หรือไม่",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ลบรูป"
      }).then(async result => {
        if (result.value) {
          this.dataImg = null;
          this.productGroup.mainImage = null;
          await this.$http.post(
            this.$store.state.apiURL +
              "/api/productgroup/remove-main-image/" +
              this.productGroup.productGroupId,
            {},
            this.$store.state.auth.apiHeader
          );
          // this.deleteImage(imageid, indextr);
        }
      });
    },

    async createBase64Image(fileObject) {
      const reader = new FileReader();

      reader.onload = e => {
        this.image = e.target.result;
      };
      reader.readAsBinaryString(fileObject);
    },

    async updateCurrImg(input) {
      // this.$vs.loading({ type: "radius" });

      if (input.target.files && input.target.files[0]) {
        var reader = new FileReader();
        reader.onload = async e => {
          this.dataImg = e.target.result;
          await this.$http.post(
            this.$store.state.apiURL +
              "/api/productgroup/save-main-image/" +
              this.productGroup.productGroupId,
            { base64Format: this.dataImg },
            this.$store.state.auth.apiHeader
          );
          // this.productGroup.mainImage = this.productgroupid;
          // console.log("i this.dataImg >>> ", this.dataImg);
        };
        reader.readAsDataURL(input.target.files[0]);

        this.$vs.loading.close();
      }
    }
    // buttonText: {
    //   type: String,
    //   default: "Click"
    // }
  },
  components: {
    Multiselect,
    "v-select": vSelect,
    CurrencyValue,
    AddImageComponent,
    UpdateProductSidebar
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
.overlay_a {
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: aquamarine;
}

@media only screen and (max-width: 578px) {
  #main-background {
    width: 100% !important;
    padding: 0 !important;
  }
  #controller-panel {
    width: 91% !important;
  }
}
</style>